.CalcGrid {
  position: relative; 
  float: left;
  max-width: 100%;
  background-color: white;
  border-radius: 5px;
  overflow: auto;
  user-select: none;
}

.CalcGrid-cell {
  display: inline-block;
  width: 100px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 22px;
}

.CalcGrid-cell:hover {
  background-color: #F7FAFC;
}

@keyframes invalid {
  0% {background-color: transparent;}
  50% {background-color: #FED7D7;}
  100% {background-color: transparent;}
}

.CalcGrid-cell.invalid {
  animation: invalid 1s infinite;
}

input.CalcGrid-cell {
  box-sizing: border-box;
  border: none;
  outline: 0;
  color: inherit;
  font-family: inherit;
}

.CalcGrid-header {
  color: #CBD5E0;
}

.CalcGrid-row {
  white-space: nowrap;
}

.CalcGrid > .CalcGrid-row:first-child > .CalcGrid-cell:first-child,
.CalcGrid > .CalcGrid-row:last-child > .CalcGrid-cell:first-child {
  margin-left: 100px;
}

.CalcGrid-rowsum {
  color: #38B2AC;
}

.CalcGrid-colprod {
  color: #ED64A6;
}