@import url(https://fonts.googleapis.com/css2?family=Questrial&display=swap);
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #9F7AEA;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #805AD5;
}

.App {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 30px;
  color: #2D3748;
  background-color: #EDF2F7;
  font-family: 'Questrial', sans-serif;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.App-h1 {
  margin: 50px 0;
  font-size: 100px;
}

.App-h2 {
  margin: 10px 0;
  font-size: 50px;
}

.App-label {
  display: block;
  margin-top: 20px;
  width: 150px;
  height: 25px;
  line-height: 25px;
  text-transform: uppercase;
  font-size: 22px;
}

.App-input {
  box-sizing: border-box;
  float: right;
  width: 60px;
  height: 25px;
  border: 0;
  outline: 0;
  font-family: inherit;
  font-size: 22px;
  text-align: center;
  color: #9F7AEA;
}

.App-card {
  padding: 20px;
  width: 33%;
  min-width: 230px;
  max-width: 370px;
  border-radius: 5px;
  background-color: white;
}

.CalcGrid {
  margin-top: 30px;
}

.CalcGrid {
  position: relative; 
  float: left;
  max-width: 100%;
  background-color: white;
  border-radius: 5px;
  overflow: auto;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.CalcGrid-cell {
  display: inline-block;
  width: 100px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 22px;
}

.CalcGrid-cell:hover {
  background-color: #F7FAFC;
}

@-webkit-keyframes invalid {
  0% {background-color: transparent;}
  50% {background-color: #FED7D7;}
  100% {background-color: transparent;}
}

@keyframes invalid {
  0% {background-color: transparent;}
  50% {background-color: #FED7D7;}
  100% {background-color: transparent;}
}

.CalcGrid-cell.invalid {
  -webkit-animation: invalid 1s infinite;
          animation: invalid 1s infinite;
}

input.CalcGrid-cell {
  box-sizing: border-box;
  border: none;
  outline: 0;
  color: inherit;
  font-family: inherit;
}

.CalcGrid-header {
  color: #CBD5E0;
}

.CalcGrid-row {
  white-space: nowrap;
}

.CalcGrid > .CalcGrid-row:first-child > .CalcGrid-cell:first-child,
.CalcGrid > .CalcGrid-row:last-child > .CalcGrid-cell:first-child {
  margin-left: 100px;
}

.CalcGrid-rowsum {
  color: #38B2AC;
}

.CalcGrid-colprod {
  color: #ED64A6;
}
